import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet porta un capell de fins a 15 cm de diàmetre, hemisfèric i posteriorment pla amb el marge llis. És de color castany clar ocraci amb tons grisencs o marronencs amb la part superior untuosa al tacte i coberta de berrugues més o menys adherides. Davall aquest es troben les làmines que són un poc decurrents i blanques. El peu és robust, blanc i més prim a la part superior. Porta un anell persistent, molt ample, estriat a la part superior i pot estar clivellat o dentat. Presenta volva generalment disgregada en forma d’esquames. Les espores són blanques en massa, el·lipsoïdals, de 8-10 x 6-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      